<template>
  <v-container v-if="loading" class="d-flex justify-center align-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-container>

  <v-container v-else-if="!loading">
    <v-row>
      <v-spacer></v-spacer>
      <v-col class="text-end">
        <v-btn variant="flat" color="primary" class="text-none" @click="print" prepend-icon="mdi-printer">Print</v-btn>
      </v-col>
    </v-row>

    <v-container ref="printArea">
      <v-row align="center">
        <v-col class="d-flex flex-wrap justify-space-between">
          <div>
            <p class="text-h4 text-primary font-weight-bold">E-Ticket</p>
            <p class="text-black font-weight-regular">
              {{ `ID Transaksi: ${transaction.transaction_id}` }}
            </p>
          </div>

          <div class="d-none d-sm-block">
            <v-img src="@/assets/logo.svg" lazy-src="@/assets/logo.svg" width="100" height="100"
              class="ml-auto"></v-img>
          </div>
        </v-col>
      </v-row>

      <template v-for="(order, orderIndex) in transaction.order" :key="orderIndex">
        <template v-if="order.is_rombongan">
          <p class="text-h4 text-black font-weight-medium">
            Tiket
            <span class="text-h4 font-weight-light">{{ orderIndex + 1 }}</span>
            dari
            <span class="text-h4 font-weight-light">{{
              orderIndex + 1
            }}</span>
          </p>

          <p class="text-body-1 font-weight-regular">
            {{ `TIKET ROMBONGAN` }}
          </p>

          <v-row justify="center" class="mt-2 mx-1 text-center">
            <v-col class="bg-bg-background-dark" cols="12" xxl="auto" lg="auto" md="auto">
              <p class="text-h6">Barcode</p>
            </v-col>

            <v-spacer class="bg-bg-background-dark"></v-spacer>

            <v-col class="bg-bg-background-dark d-none d-md-block">
              <p class="text-h6">Ticket Detail/Detail Tiket</p>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col cols="12" xxl="auto" lg="auto" md="auto">
              <v-img v-if="order.order_details[0].ticket[0].qr" width="auto" height="auto"
                :src="'data:image/png;base64, ' + order.order_details[0].ticket[0].qr" class="mx-auto">
              </v-img>
            </v-col>

            <v-divider :thickness="3" vertical></v-divider>

            <v-col cols="12" xxl="7" lg="7" md="7">
              <v-row class="text-end">
                <v-col cols="6">
                  Ticket Number/<br />
                  Nomor Tiket
                </v-col>
                <v-col class="text-start text-break" cols="6">
                  {{ `${order.order_id} (Tiket Rombongan)` }}
                </v-col>

                <v-col cols="6">
                  Customer Email/<br />
                  Email Pemesan
                </v-col>
                <v-col class="text-start text-break" cols="6">
                  {{ order.user_email ?? "-" }}
                </v-col>

                <v-col v-if="order.order_details[0].product.parent_category_name == 'Event'" cols="6">
                  Event Date/<br />
                  Tanggal Acara
                </v-col>
                <v-col v-if="order.order_details[0].product.parent_category_name == 'Event'"
                  class="text-start text-break" cols="6">
                  {{
                    `${formatDate(
                      order.order_details[0].product.event_start,
                      "DD/MM/YYYY, HH:mm"
                    )}`
                  }}
                </v-col>

                <v-col v-if="order.order_details[0].product.parent_category_name != 'Event'" cols="6">
                  Visit Date/<br />
                  Tanggal Kunjungan
                </v-col>
                <v-col v-if="order.order_details[0].product.parent_category_name != 'Event'" class="text-start"
                  cols="6">
                  {{ `: Selama tiket masih berlaku` }}
                </v-col>

                <v-col v-if="order.order_details[0].product.parent_category_name != 'Event'" cols="6">
                  Validity Period/<br />
                  Masa Berlaku
                </v-col>
                <v-col v-if="order.order_details[0].product.parent_category_name != 'Event'" class="text-start"
                  cols="6">
                  {{
                    `: ${formatDate(
                      order.order_details[0].product.product_valid_period_start,
                      "DD/MM/YYYY, HH:mm",
                      order.order_details[0].product.product_expiry_range
                    )}`
                  }}
                </v-col>
              </v-row>
            </v-col>

          </v-row>

          <v-row>
            <v-divider :thickness="3"></v-divider>

            <v-alert text="Silahkan tunjukkan barcode ini kepada petugas sesampainya kamu di
                  sana untuk di-scan / Please show this barcode to the officer when
                  you get there to be scanned" type="info" color="secondary" variant="tonal" class="my-4"></v-alert>
          </v-row>

          <v-row>
            <v-divider class="border-dashed py-2" :thickness="3"></v-divider>
          </v-row>

          <p class="text-h5 font-weight-medium mt-4 mb-2">
            {{ order.order_details[0].product.product_name }}
          </p>

          <v-row>
            <v-col cols="3" align-self="center">
              <v-img :src="order.order_details[0].product.product_details[0].productdetail_image_url" :lazy-src="order.order_details[0].product.product_details[0].productdetail_image_url
                " aspect-ratio="16/9" width="400"></v-img>
            </v-col>

            <v-col>
              <div class="d-flex flex-column justify-space-between">
                <v-spacer class="my-5"></v-spacer>

                <div v-if="order.order_details[0].product.parent_category_name == 'Event'" class="my-5">
                  <p class="font-weight-bold">Date/Event/Tanggal Acara:</p>
                  <p>
                    {{
                      `${formatDate(
                        order.order_details[0].product.event_start,
                        "DD/MM/YYYY, HH:mm"
                      )} - ${formatDate(
                        order.order_details[0].product.event_end,
                        "DD/MM/YYYY, HH:mm"
                      )}`
                    }}
                  </p>
                </div>

                <div v-if="order.order_details[0].product.parent_category_name != 'Event'" class="my-5">
                  <p class="font-weight-bold">Open/Buka:</p>
                  <template v-for="(bh, bhIndex) in order.order_details[0].product.business_hours" :key="bhIndex">
                    <p>{{ `${dayName(bh.day_start)} - ${dayName(bh.day_end)}, ${bh.open_hour} - ${bh.closing_hour}` }}
                    </p>
                  </template>
                </div>

                <div class="my-5">
                  <v-btn class="text-none" variant="flat" rounded="lg" color="bg-primary-container-dark"
                    :href="`mailto:${order.order_details[0].product.email_contact}`">
                    <div class="text-break">
                      {{ order.order_details[0].product.email_contact }}
                    </div>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="border-dashed py-2" :thickness="3"></v-divider>
          </v-row>
        </template>
        <template v-else>
          <template v-for="(detail, detailIndex) in order.order_details" :key="detailIndex">
            <template v-for="(ticket, ticketIndex) in detail.ticket" :key="ticketIndex">
              <p class="text-h4 text-black font-weight-medium">
                Tiket
                <span class="text-h4 font-weight-light">{{ ticketIndex + 1 }}</span>
                dari
                <span class="text-h4 font-weight-light">{{
                  detail.ticket.length
                }}</span>
              </p>

              <p class="text-body-1 font-weight-regular">
                {{ detail.product.producttype_name }}
              </p>

              <v-row justify="center" class="mt-2 mx-1 text-center">
                <v-col class="bg-bg-background-dark" cols="12" xxl="auto" lg="auto" md="auto">
                  <p class="text-h6">Barcode</p>
                </v-col>

                <v-spacer class="bg-bg-background-dark"></v-spacer>

                <v-col class="bg-bg-background-dark d-none d-md-block">
                  <p class="text-h6">Ticket Detail/Detail Tiket</p>
                </v-col>
              </v-row>

              <v-row class="mb-2">
                <v-col cols="12" xxl="auto" lg="auto" md="auto">
                  <v-img v-if="ticket.qr" width="auto" height="auto" :src="'data:image/png;base64, ' + ticket.qr"
                    class="mx-auto">
                  </v-img>
                </v-col>
                <v-divider :thickness="3" vertical></v-divider>

                <v-col cols="12" xxl="7" lg="7" md="7">
                  <v-row class="text-end">
                    <v-col cols="6">
                      Ticket Number/<br />
                      Nomor Tiket
                    </v-col>
                    <v-col class="text-start text-break" cols="6">
                      {{ `${ticket.ticket_number}` }}
                    </v-col>

                    <v-col cols="6">
                      Customer Email/<br />
                      Email Pemesan
                    </v-col>
                    <v-col class="text-start text-break" cols="6">
                      {{ order.user_email ?? "-" }}
                    </v-col>

                    <v-col v-if="detail.product.parent_category_name == 'Event'" cols="6">
                      Event Date/<br />
                      Tanggal Acara
                    </v-col>
                    <v-col v-if="detail.product.parent_category_name == 'Event'" class="text-start text-break" cols="6">
                      {{
                        `${formatDate(
                          detail.product.event_start,
                          "DD/MM/YYYY, HH:mm"
                        )}`
                      }}
                    </v-col>

                    <v-col v-if="detail.product.parent_category_name != 'Event'" cols="6">
                      Visit Date/<br />
                      Tanggal Kunjungan
                    </v-col>
                    <v-col v-if="detail.product.parent_category_name != 'Event'" class="text-start" cols="6">
                      {{ `: Selama tiket masih berlaku` }}
                    </v-col>

                    <v-col v-if="detail.product.parent_category_name != 'Event'" cols="6">
                      Validity Period/<br />
                      Masa Berlaku
                    </v-col>
                    <v-col v-if="detail.product.parent_category_name != 'Event'" class="text-start" cols="6">
                      {{
                        `: ${formatDate(
                          detail.product.product_valid_period_start,
                          "DD/MM/YYYY, HH:mm",
                          detail.product.product_expiry_range
                        )}`
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-divider :thickness="3"></v-divider>

                <v-alert text="Silahkan tunjukkan barcode ini kepada petugas sesampainya kamu di
                  sana untuk di-scan / Please show this barcode to the officer when
                  you get there to be scanned" type="info" color="secondary" variant="tonal" class="my-4"></v-alert>
              </v-row>

              <v-row>
                <v-divider class="border-dashed py-2" :thickness="3"></v-divider>
              </v-row>

              <p class="text-h5 font-weight-medium mt-4 mb-2">
                {{ detail.product.product_name }}
              </p>

              <v-row>
                <v-col cols="3" align-self="center">
                  <v-img :src="detail.product.product_details[0].productdetail_image_url" :lazy-src="detail.product.product_details[0].productdetail_image_url
                    " aspect-ratio="16/9" width="400"></v-img>
                </v-col>

                <v-col>
                  <div class="d-flex flex-column justify-space-between">
                    <v-spacer class="my-5"></v-spacer>

                    <div v-if="detail.product.parent_category_name == 'Event'" class="my-5">
                      <p class="font-weight-bold">Date/Event/Tanggal Acara:</p>
                      <p>
                        {{
                          `${formatDate(
                            detail.product.event_start,
                            "DD/MM/YYYY, HH:mm"
                          )} - ${formatDate(
                            detail.product.event_end,
                            "DD/MM/YYYY, HH:mm"
                          )}`
                        }}
                      </p>
                    </div>

                    <div v-if="detail.product.parent_category_name != 'Event'" class="my-5">
                      <p class="font-weight-bold">Open/Buka:</p>
                      <template v-for="(bh, bhIndex) in detail.product.business_hours" :key="bhIndex">
                        <p>{{ `${dayName(bh.day_start)} - ${dayName(bh.day_end)}, ${bh.open_hour} - ${bh.closing_hour}`
                        }}
                        </p>
                      </template>
                    </div>

                    <div class="my-5">
                      <v-btn class="text-none" variant="flat" rounded="lg" color="bg-primary-container-dark"
                        :href="`mailto:${detail.product.email_contact}`">
                        <div class="text-break">
                          {{
                            detail.product.email_contact
                          }}
                        </div>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-divider class="border-dashed py-2" :thickness="3"></v-divider>
              </v-row>
            </template>
          </template>
        </template>
      </template>

      <p class="text-body-1 font-weight-medium mt-8">
        Please reach out e-tix if you have some trouble with the ticket /<br />
        Hubungi kontak e-tix jika kamu mengalami kendala
      </p>
      <v-btn prepend-icon="mdi-email" variant="text" color="secondary-60" class="text-none"
        href="mailto:etix@softorb.co.id"><span class="text-black">etix@softorb.co.id</span></v-btn>

      <div class="bg-black text-center px-0">
        <p>e-tix - PT Softorb Technology Indonesia</p>
        <p>{{ `Copyright © ${new Date().getFullYear()}` }}</p>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { utcToLocale } from "@/utils/dateFormatter";
import moment from "moment";
import { useVueToPrint } from "vue-to-print";

export default {
  data() {
    return {
      loading: true,
      transactionId: this.$route.params.transaction_id,
      transaction: null,
    };
  },

  computed: {},

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      this.axios
        .get(`product/transaction/${this.transactionId}`)
        .then((response) => {
          const result = response.data.response;
          const order = result.order.map((order) => {
            if (order.is_rombongan) {
              order.order_details[0].ticket[0].qr = null;
              this.getTicketQR(order.order_details[0].ticket[0].ticket_number).then((qr) => {
                order.order_details[0].ticket[0].qr = qr;
              });
            } else {
              order.order_details.map(async (detail) => {
                const tickets = await Promise.all(
                  detail.ticket.map(async (ticket) => {
                    ticket.qr = await this.getTicketQR(ticket.ticket_number);
                    return ticket;
                  })
                );
                detail.ticket = tickets;
                return detail;
              });
            }
            return order;
          });
          result.order = order;
          this.transaction = result;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },

    async getTicketQR(ticketNumber) {
      const ticketQR = await this.axios.get(`ticket/qr/${ticketNumber}/get`);
      return ticketQR.data.response.qr_code;
    },

    formatDate(date, format, range) {
      const localDate = moment.utc(date).local();
      const utcOffset = localDate.utcOffset() / 60;
      let timezone = "WIB";
      if (utcOffset === 8) timezone = "WITA";
      else if (utcOffset === 9) timezone = "WIT";

      if (range) {
        const validPeriod = moment(date).add('days', range)
        return `${utcToLocale(validPeriod, format)} ${timezone}`;
      }
      return `${utcToLocale(date, format)} ${timezone}`;
    },

    dayName(dayNumber) {
      moment.locale('id')
      const dayNumberModulo = dayNumber % 7
      return moment().day(dayNumberModulo).format('dddd');
    },

    print() {
      const { handlePrint } = useVueToPrint({
        content: this.$refs.printArea,
        documentTitle: `E-Tix Summary Ticket - ${this.transaction?.transaction_id}`,
        removeAfterPrint: true,
      });
      handlePrint();
    },
  },
};
</script>
